import { css } from '@emotion/react';
import { FC, ReactNode } from 'react';

import { colors } from '../../theme/colors';

type Props = {
  children: ReactNode;
  maxWidth?: string | number;
  lineHeight?: string | number;
};

export const SectionHeading: FC<Props> = ({
  children,
  maxWidth = 224,
  lineHeight = 1,
}) => {
  return <h2 css={SectionHeadingStyle(maxWidth, lineHeight)}>{children}</h2>;
};

const SectionHeadingStyle = (
  maxWidth?: string | number,
  lineHeight?: string | number
) =>
  css({
    width: 'fit-content',
    fontSize: 40,
    fontWeight: 700,
    paddingBottom: 20,
    borderBottom: `solid 3px ${colors.primary}`,
    stroke: '#fff',
    lineHeight: lineHeight,

    br: { display: 'none' },

    strong: {
      color: colors.primary,
    },

    '@media(max-width: 1119px)': {
      fontSize: 28,
    },

    '@media(max-width: 768px)': {
      maxWidth: maxWidth,
      textAlign: 'center',

      br: { display: 'inline' },
    },
  });
