import { css } from '@emotion/react';
import { useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleListHeading } from '../../components/organisms/ArticleListHeading';
import { SectionHeading } from '../../components/atoms/SectionHeading';
import { PaildButton } from '../../components/atoms/PaildButton';
import { colors } from '../../theme/colors';

export const Head = () => (
  <HeadInner
    path='/partner/'
    title='paildパートナープログラム'
    description='paild（ペイルド）の販売パートナー募集しています。paildをお知り合いの企業にご紹介いただき、紹介先企業がpaildを使って決済した場合、双方に特典をお渡しするプログラムをご用意しています。'
  />
);

const PartnerPage = () => {
  const scheduleSectionRef = useRef<HTMLElement>(null);

  const anchorScroll = (y: number) => {
    scrollBy({
      top: innerWidth < 1120 ? y - 84 : y - 168,
      left: 0,
      behavior: 'smooth',
    });
  };

  const links = [{ name: '販売パートナー募集', path: '/partner/' }];

  return (
    <Layout links={links}>
      <ArticleListHeading category='partner' title='販売パートナー募集' />
      <section css={TopSectionStyle}>
        <SectionHeading maxWidth='100%'>パートナープログラム</SectionHeading>
        <p css={DescriptionStyle}>
          paildをお知り合いの企業にご紹介いただき、紹介先企業がpaildを使って決済した場合、
          <br />
          双方に特典をお渡しするプログラムをご用意しています。
        </p>
        <StaticImage
          src='../../images/partner/partner.png'
          alt='販売パートナー募集'
          quality={100}
          placeholder='none'
        />
        <p css={{ color: colors.gray }}>
          paildの概要と、パートナープログラムについてオンラインにてご説明いたします。
        </p>
        <PaildButton
          isAnchor
          background={colors.primary}
          onClick={() => {
            if (!scheduleSectionRef.current) return;
            anchorScroll(scheduleSectionRef.current.getBoundingClientRect().y);
          }}
        >
          オンライン説明を予約
        </PaildButton>
      </section>
      <section css={MeritSectionStyle}>
        <SectionHeading>メリット</SectionHeading>
        <div css={SectionInnerStyle}>
          <div css={ContentStyle}>
            <StaticImage
              src='../../images/partner/merit-1.svg'
              alt='紹介先・紹介元双方に特典'
              quality={100}
              placeholder='none'
              objectFit='contain'
            />
            <h3>紹介先・紹介元双方に特典</h3>
            <p>
              顧問先のpaild導入に対して、紹介手数料をお支払いいたします。
              <br />
              また、導入した企業様にも特典をご用意しております。
            </p>
          </div>
          <div css={ContentStyle}>
            <StaticImage
              src='../../images/partner/merit-2.svg'
              alt='紹介先企業の会計業務をDX化'
              quality={100}
              placeholder='none'
              objectFit='contain'
            />
            <h3>紹介先企業の会計業務をDX化</h3>
            <p>
              法人支出の最適化を実現する「paild（ペイルド）」を、新規顧客獲得のツールとしてぜひご活用ください。
            </p>
          </div>
        </div>
      </section>
      <section css={PartnerSectionStyle}>
        <SectionHeading maxWidth='100%'>パートナー企業の声</SectionHeading>
        <div css={SectionInnerStyle}>
          <div css={ContentStyle}>
            <StaticImage
              src='../../images/partner/partner-1.svg'
              alt='士業'
              quality={100}
              placeholder='none'
              objectFit='contain'
            />
            <h3>士業</h3>
            <p>
              顧問先企業にpaildを導入することで、会計処理に必要な情報収集が
              <br />
              ラクになり、記帳代行業務が効率的になりました。
            </p>
          </div>
          <div css={ContentStyle}>
            <StaticImage
              src='../../images/partner/partner-2.svg'
              alt='コンサルティング'
              quality={100}
              placeholder='none'
              objectFit='contain'
            />
            <h3>コンサルティング</h3>
            <p>
              法人カードに加え、領収書管理や仕訳、インボイス対応など様々な機能が搭載されているので、紹介した際に興味を持ってもらえます。
            </p>
          </div>
        </div>
      </section>
      <section css={FlowSectionStyle}>
        <SectionHeading maxWidth='100%'>パートナー登録の流れ</SectionHeading>
        <div css={RegistrationFlowStyle}>
          <ul css={FlowWrapperStyle}>
            <li css={[FlowStyle, BottomLine]}>
              <div css={StepStyle}>
                <p css={{ fontSize: 11 }}>step</p>
                <p css={{ fontSize: 22 }}>1</p>
              </div>
              <div css={FlowDescriptionStyle}>
                <StaticImage
                  src='../../images/partner/flow-1.svg'
                  alt='ご案内'
                  quality={100}
                  css={ImageStyle}
                  objectFit='contain'
                  placeholder='none'
                />
                <p css={FlowTitleStyle}>ご案内</p>
                <p>
                  オンラインでpaildの概要とパートナープログラムについてご説明いたします。
                </p>
              </div>
            </li>
            <li css={[FlowStyle, TopLine, BottomLine]}>
              <div css={StepStyle}>
                <p css={{ fontSize: 11 }}>step</p>
                <p css={{ fontSize: 22 }}>2</p>
              </div>
              <div css={FlowDescriptionStyle}>
                <StaticImage
                  src='../../images/partner/flow-2.svg'
                  alt='ご登録'
                  quality={100}
                  css={ImageStyle}
                  objectFit='contain'
                  placeholder='none'
                />
                <p css={FlowTitleStyle}>ご登録</p>
                <p>紹介手数料のお支払いに必要な情報をご登録いただきます。</p>
              </div>
            </li>
            <li css={[FlowStyle, TopLine]}>
              <div css={StepStyle}>
                <p css={{ fontSize: 11 }}>step</p>
                <p css={{ fontSize: 22 }}>3</p>
              </div>
              <div css={FlowDescriptionStyle}>
                <StaticImage
                  src='../../images/partner/flow-3.svg'
                  alt='ご紹介'
                  quality={100}
                  css={ImageStyle}
                  objectFit='contain'
                  placeholder='none'
                />
                <p css={FlowTitleStyle}>ご紹介</p>
                <p>paildをお知り合いの企業様にご紹介ください。</p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section css={ScheduleSectionStyle} ref={scheduleSectionRef}>
        <div css={ScheduleInnerStyle}>
          <StaticImage
            src='../../images/registration/background_registration_1.svg'
            alt='background_registration_1'
            quality={100}
            placeholder='none'
            css={{
              position: 'absolute',
              top: -30,
              left: 50,
              '@media(max-width: 768px)': {
                display: 'none',
              },
            }}
          />
          <StaticImage
            src='../../images/registration/background_registration_2.svg'
            alt='background_registration_2'
            quality={100}
            placeholder='none'
            css={{
              position: 'absolute',
              bottom: -30,
              right: 50,
              '@media(max-width: 768px)': {
                display: 'none',
              },
            }}
          />
          <iframe
            src='https://meetings.hubspot.com/baku-yamamura/paild-partner-program-overview?embed=true'
            css={{
              width: '100%',
              height: 670,
              border: 'none',
              zIndex: 5,
            }}
          />
        </div>
      </section>
    </Layout>
  );
};

const TopSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: 1120,
  padding: '0 17px',
  gap: 40,

  '@media(max-width: 1119px)': {
    gap: 30,
  },
});

const DescriptionStyle = css({
  paddingTop: 20,
  fontSize: 16,

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
  '@media(max-width: 768px)': {
    br: {
      display: 'none',
    },
  },
});

const MeritSectionStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: colors.secondary,
  paddingTop: 60,
  paddingBottom: 120,

  gap: 60,

  '@media(max-width: 1119px)': {
    padding: '60px 17px',
    gap: 30,
  },
});

const SectionInnerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 1120,
  gap: 60,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 30,
  },

  '@media(max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 17px',
  },
});

const ContentStyle = css({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
  maxWidth: 530,

  h3: {
    fontSize: 20,
    color: colors.primary,
  },

  p: {
    fontSize: 16,

    '@media(max-width: 1119px)': {
      fontSize: 14,

      br: {
        display: 'none',
      },
    },
  },
});

const PartnerSectionStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  gap: 60,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 30,
  },
});

const FlowSectionStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  gap: 60,
  padding: '60px 17px',

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 30,
  },
});

const RegistrationFlowStyle = css({
  display: 'flex',
  width: '100%',
  maxWidth: 1120,
  gap: 30,

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
  },
});

const FlowWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: 70,
  background: colors.secondary,
  borderRadius: 16,
  padding: '30px 20px 40px',
  listStyle: 'none',

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
  },
});

const FlowStyle = css({
  width: '100%',
  maxWidth: 250,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 30,

  '@media(max-width: 1119px)': {
    flexDirection: 'row',
    maxWidth: '100%',
    gap: 20,
  },
});

const StepStyle = css({
  minWidth: 80,
  height: 80,
  borderRadius: '50%',
  border: `2px solid ${colors.black}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: colors.white,

  p: { fontWeight: 500 },
});

const ImageStyle = css({
  width: '100%',
  minWidth: 120,
  maxWidth: 120,
});

const FlowTitleStyle = css({
  width: '100%',
  minWidth: 100,
  maxWidth: 100,
  minHeight: 58,
  fontSize: 20,
  fontWeight: 700,
  textAlign: 'center',
  color: colors.primary,

  '@media(max-width: 1119px)': {
    minHeight: 0,
  },

  '@media(max-width: 768px)': {
    maxWidth: '100%',
    br: {
      display: 'none',
    },
  },
});

const FlowDescriptionStyle = css({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 30,

  '@media(max-width: 1119px)': {
    flexDirection: 'row',
    gap: 20,
  },

  '@media(max-width: 768px)': {
    flexDirection: 'column',
  },
});

const BottomLine = css({
  position: 'relative',

  '&:after': {
    content: '""',
    position: 'absolute',
    background: colors.black,
    height: 2,
    width: 'calc((100% - 80px) / 2 + 35px)',
    top: 39,
    left: 'calc((100% - 80px) / 2 + 80px)',

    '@media(max-width: 1119px)': {
      width: 2,
      height: 'calc((100% - 80px) / 2 + 35px)',
      left: 39,
      top: 'calc((100% - 80px) / 2 + 80px)',
    },
  },
});

const TopLine = css({
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    background: colors.black,
    height: 2,
    width: 'calc((100% - 80px) / 2 + 35px)',
    top: 39,
    right: 'calc((100% - 80px) / 2 + 80px)',

    '@media(max-width: 1119px)': {
      top: 'auto',
      width: 2,
      height: 'calc((100% - 80px) / 2 + 35px)',
      left: 39,
      bottom: 'calc((100% - 80px) / 2 + 80px)',
    },
  },
});

const ScheduleSectionStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 17px',
  gap: 60,

  '@media(max-width: 1119px)': {
    gap: 30,
    padding: 0,
  },
});

const ScheduleInnerStyle = css({
  width: '100%',
  maxWidth: 1820,
  padding: '80px 12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 40,
  background: colors.secondary,
  borderRadius: 16,
  position: 'relative',

  '@media(max-width: 1119px)': {
    borderRadius: 0,
    padding: '64px 17px',
  },

  '@media(max-width: 768px)': {
    borderRadius: 0,
    padding: '32px 17px',
  },
});

export default PartnerPage;
